.relatedChatsDark::-webkit-scrollbar {
    width: 10px;
    background-color: #e6ebf5 !important;
}
  
.relatedChatsDark::-webkit-scrollbar-track {
    background-color: #262e35 !important;
}

.relatedChatsDark::-webkit-scrollbar-thumb {
    background-color: #36404a !important;
    border-radius: 5px;
}